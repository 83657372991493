<script lang="ts">
	import { onMount } from 'svelte'
	export let lat: number
	export let long: number

	import { city, state, setGeo, refreshGeoVercel } from '~/stores/geo'

	onMount(async () => {
		if (!$city || !$state) {
		}
		refreshGeoVercel()
	})

	$: if (lat && long) {
		setGeo(lat, long)
	}
</script>
